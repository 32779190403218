import React from 'react';
import clsx from 'clsx';
import { CheckIcon, GridPattern } from './pricing-pairs';

function Plan({
  name,
  description,
  featured = false,
  features,
}: {
  name: string;
  description: string;
  featured?: boolean;
  features?: String[];
}) {
  return (
    <div
      className={clsx(
        'relative px-4 py-16 sm:rounded-5xl sm:px-10 md:py-12 lg:px-12',
        featured && 'bg-blue-600 sm:shadow-lg'
      )}
    >
      {featured && (
        <div className="absolute inset-0 text-white/10 [mask-image:linear-gradient(white,transparent)]">
          <GridPattern x="50%" y="50%" />
        </div>
      )}
      <div className="relative flex flex-col">
        {!featured && (
          <h3
            className={clsx(
              'mt-7 text-lg font-semibold tracking-tight',
              featured ? 'text-white' : 'text-slate-900'
            )}
          >
            {name}
          </h3>
        )}
        <p
          className={clsx(
            'mt-2 text-lg tracking-tight',
            featured ? 'text-white' : 'text-slate-600'
          )}
        >
          <div
            className=""
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </p>

        {featured && (
          <>
            <p className="order-first flex font-display font-bold">
              <span
                className={clsx(
                  'mt-1 text-4xl tracking-tight',
                  featured ? 'text-white' : 'text-slate-900'
                )}
              >
                {name}
              </span>
            </p>

            <div className="mt-8 w-full">
              <div className="inline-flex w-full justify-center rounded-md py-1 px-4 text-base font-semibold tracking-tight shadow-sm  bg-white text-blue-600">
                Features
              </div>
            </div>
          </>
        )}
        <div className="order-last mt-8">
          <ul
            className={clsx(
              '-my-2 divide-y text-base tracking-tight',
              featured
                ? 'divide-white/10 text-white'
                : 'divide-slate-200 text-slate-900'
            )}
          >
            {features &&
              features.map((feature, idx) => (
                <li key={idx} className="flex py-2">
                  <CheckIcon
                    className={clsx(
                      'h-8 w-8 flex-none',
                      featured ? 'fill-white' : 'fill-slate-600'
                    )}
                  />
                  <span className="ml-4">{feature}</span>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

function Dedicated() {
  return (
    <div className="grid grid-cols-1">
      <section
        id="pricing"
        aria-labelledby="pricing-title"
        className="scroll-mt-14 pb-8 pt-16 sm:scroll-mt-32 sm:pb-10 sm:pt-20 lg:pb-2"
      >
        <div className="mx-auto max-w-5xl lg:px-6">
          <div className="bg-slate-50 md:rounded-6xl">
            <div className="grid sm:px-6 sm:pb-16 md:grid-cols-2 md:px-8 md:pt-8 lg:p-20 lg:pt-12 items-center">
              <Plan
                name="Want a dedicated Short-Code?"
                description="<p>We do all the heavy lifting, from compliance to network integration.<p/><p class='mt-8'>Pricing for dedicated short-codes may vary based on the USSD code selected and respective network and are subject to change without prior notice.<p/>"
              />

              <Plan
                featured
                name="Dedicated USSD Short-Codes"
                description=" Position your business for seamless, professional customer engagement."
                features={[
                  'Custom short-code to match your brand',
                  'Endless extensions at your disposal',
                  'Earn passive revenue by reselling your extensions as shared codes with us',
                ]}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Dedicated;
