import React, { useId } from 'react';
import { Link } from 'react-router-dom';

function CircleBackground({
  color,
  ...props
}: React.ComponentPropsWithoutRef<'svg'> & {
  color: string;
}) {
  let id = useId();

  return (
    <svg
      viewBox="0 0 558 558"
      width="558"
      height="558"
      fill="none"
      aria-hidden="true"
      {...props}
    >
      <defs>
        <linearGradient
          id={id}
          x1="79"
          y1="16"
          x2="105"
          y2="237"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
      </defs>
      <path
        opacity=".2"
        d="M1 279C1 125.465 125.465 1 279 1s278 124.465 278 278-124.465 278-278 278S1 432.535 1 279Z"
        stroke={color}
      />
      <path
        d="M1 279C1 125.465 125.465 1 279 1"
        stroke={`url(#${id})`}
        strokeLinecap="round"
      />
    </svg>
  );
}

function CallToAction() {
  return (
    <section
      id="get-free-shares-today"
      className="relative overflow-hidden bg-gray-900 py-20 sm:py-28"
    >
      <div className="absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2">
        <CircleBackground
          color="#fff"
          className="animate-spin-slower"
        />
      </div>
      <div className="container relative">
        <div className="mx-auto max-w-lg sm:text-center">
          <h2 className="text-3xl font-medium tracking-tight text-white sm:text-4xl">
            Get unique short-codes today!
          </h2>
          <p className="mt-4 text-[20px] text-gray-300">
            Make it easy for your audience to connect with you and
            simplify your communication across all networks.
          </p>
          <div className="mt-12 flex justify-center">
            <Link
              to="https://app.uzoussd.com/app/register"
              className="bg-primary px-12 py-2 rounded-full text-white font-bold text-[20px] bg-gradient-to-b from-yellow via-primary to-orange600"
            >
              Get Shortcode
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CallToAction;
