import { useEffect, useId, useState } from 'react';
import { Link } from 'react-router-dom';
import USSDEmpower from '../assets/images/hero/ussd-guy.png';
import Slider from './Slider';
import { Button } from '@headlessui/react';
import clsx from 'clsx';

function BackgroundIllustration(
  props: React.ComponentPropsWithoutRef<'div'>
) {
  let id = useId();

  return (
    <div {...props}>
      <svg
        viewBox="0 0 1026 1026"
        fill="none"
        aria-hidden="true"
        className="absolute inset-0 h-full w-full animate-spin-slow"
      >
        <path
          d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z"
          stroke="#D4D4D4"
          strokeOpacity="0.7"
        />
        <path
          d="M513 1025C230.23 1025 1 795.77 1 513"
          stroke={`url(#${id}-gradient-1)`}
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id={`${id}-gradient-1`}
            x1="1"
            y1="513"
            x2="1"
            y2="1025"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#06b6d4" />
            <stop offset="1" stopColor="#06b6d4" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        viewBox="0 0 1026 1026"
        fill="none"
        aria-hidden="true"
        className="absolute inset-0 h-full w-full animate-spin-reverse-slower"
      >
        <path
          d="M913 513c0 220.914-179.086 400-400 400S113 733.914 113 513s179.086-400 400-400 400 179.086 400 400Z"
          stroke="#D4D4D4"
          strokeOpacity="0.7"
        />
        <path
          d="M913 513c0 220.914-179.086 400-400 400"
          stroke={`url(#${id}-gradient-2)`}
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id={`${id}-gradient-2`}
            x1="913"
            y1="513"
            x2="913"
            y2="913"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#06b6d4" />
            <stop offset="1" stopColor="#06b6d4" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

const Slides = [
  {
    title: 'USSD apps empower businesses!',
    sub: 'Never miss a beat with your business. Let your customers connect to you from any phone, smart or not.',
    linkTitle: 'Get Your Free Account',
  },
  {
    title: 'Experience Fast, User-Friendly Interaction',
    sub: 'USSD provides a real-time interactive menu that is easy to use, with two-way communication up to seven times (7x) faster than SMS.',
    linkTitle: 'Get Your App Now',
  },
  {
    title: 'Happy customers and lower costs',
    sub: 'Using USSD as a self-service tool not only lowers operating expenses but also enhances customer satisfaction by empowering clients to serve themselves.',
    linkTitle: 'Start Now!',
  },
];

export function Hero() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((seconds) => (seconds < 2 ? seconds + 1 : 0));
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="overflow-hidden py-20 sm:py-32 lg:pb-32 xl:pb-36">
      <div className="container">
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-8">
          <div className="relative z-10 mx-auto max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6">
            <SlideItem
              title="Access to USSD has never been easier."
              sub={`Build your apps on Uzo now with just a few clicks <br />No technical skills needed.`}
              linkTitle="Get Your Free Account"
            />
            {/* <Slider
              config={{
                slidesToShow: 1,
                spacing: 4,
              }}
              data={Slides}
              renderItem={(item, index) => {
                return (
                  <SlideItem
                    title={item.title}
                    sub={item.sub}
                    linkTitle={item.linkTitle}
                    key={index}
                  />
                );
              }}
            /> */}
          </div>
          <div className="relative mt-10 sm:mt-20 lg:col-span-5 lg:row-span-2 lg:mt-0 xl:col-span-6">
            <BackgroundIllustration className="absolute left-1/2 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0" />
            <div className="-mx-4 h-[458px] w-[830px] px-9 [mask-image:linear-gradient(to_bottom,white_80%,transparent)] sm:mx-0 lg:absolute lg:-inset-x-10 lg:-bottom-32 lg:-top-10 lg:h-auto lg:px-0 lg:pt-10 xl:-bottom-36">
              <img
                src={USSDEmpower}
                className="mx-auto max-w-[830px]"
                alt=""
              />
            </div>
          </div>
          <div className="relative -mt-4 lg:col-span-7 lg:mt-0 xl:col-span-6">
            {/* <p className="text-left text-lg font-semibold text-gray-900">
              Build your apps on Uzo now with just a few clicks.{' '}
              <br />
              No technical skills needed.
            </p> */}
            <div className="mx-auto mt-8">
              <Link
                to="https://app.uzoussd.com/app/register"
                className="bg-primary px-12 py-2 rounded-full text-white font-bold text-[20px] bg-gradient-to-b from-yellow to-orange"
              >
                {/* {Slides[currentSlide].linkTitle} */}
                Get Your Free Account
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SlideItem({
  title,
  sub,
  linkTitle,
}: {
  title: string;
  sub: string;
  linkTitle: string;
}) {
  return (
    <>
      <h1 className="text-7xl font-medium tracking-tight text-gray-900 max-w-2xl">
        {title}
        {/* Empower your business with USSD apps! */}
        {/* Supercharge your business with USSD apps */}
      </h1>
      <p className="mt-6 text-[20px] text-gray-600 max-w-xl">
        <div dangerouslySetInnerHTML={{ __html: sub }} />
      </p>
      {/* <p className="mt-24 text-[20px] text-gray-600">
        Build your apps on Uzo now with just a few clicks. <br />
        No technical skills needed.
      </p> */}
      {/* <div className="mt-8 flex flex-wrap">
        <Link
          to="https://app.uzoussd.com/app/register"
          className="bg-primary px-12 py-2 rounded-full text-white font-bold text-[20px] bg-gradient-to-b from-yellow to-orange"
        >
          {linkTitle}
        </Link> */}
      {/* </div> */}
    </>
  );
}
