import { clsx } from 'clsx'
import { motion } from 'framer-motion'

function Row(props: { children: React.ReactNode }) {
  return <div {...props} className="group flex h-9 gap-3" />
}

function Key({
  name,
  width = 36,
  className,
  children,
}: {
  name: string
  width?: number
  className?: string
  children?: React.ReactNode
}) {
  const highlighted  = ["Star", "Eight", "Zero", "Seven", "Hash"];

  return (
    <motion.div
      animate={
        highlighted.includes(name)
          ? {
              scale: [1, 1.02, 1.02, 1],
              boxShadow: [
                `rgb(255, 255, 255) 0px 0px 0px 0px, rgba(47, 127, 221, 0.8) 0px 0px 0px 1px, rgba(47, 127, 221, 0.15) 0px 0px 3px 3px, rgba(47, 127, 221, 0.05) 0px 0px 0px 0px`,
                `rgb(255, 255, 255) 0px 0px 0px 0px, rgba(47, 127, 221, 0.8) 0px 0px 0px 1px, rgba(47, 127, 221, 0.1) 0px 0px 1px 6px, rgba(47, 127, 221, 0.05) 0px 0px 1px 15px`,
                `rgb(255, 255, 255) 0px 0px 0px 0px, rgba(47, 127, 221, 0.8) 0px 0px 0px 1px, rgba(47, 127, 221, 0.0) 0px 0px 1px 6px, rgba(47, 127, 221, 0.01) 0px 0px 1px 15px`,
                `rgb(255, 255, 255) 0px 0px 0px 0px, rgba(47, 127, 221, 0.8) 0px 0px 0px 1px, rgba(47, 127, 221, 0.0) 0px 0px 3px 3px, rgba(47, 127, 221, 0.00) 0px 0px 0px 0px`,
              ],
              transition: {
                repeat: Infinity,
                ease: 'easeInOut',
                duration: 1.75 - highlighted.indexOf(name) * 0.35,
                delay: highlighted.indexOf(name) * 0.35,
                repeatDelay: 1 + highlighted.indexOf(name) * 0.35,
              },
            }
          : undefined
      }
      variants={
        highlighted.includes(name)
          ? {
              idle: {
                scale: 1,
                boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px, rgba(47, 127, 221, 0.8) 0px 0px 0px 1px, rgba(47, 127, 221, 0.15) 0px 0px 3px 3px,rgba(47, 127, 221, 0.1) 0px 0px 0px 0px`,
              },
            }
          : undefined
      }
      style={{ width: `${width / 16}rem` }}
      className={clsx(
        className,
        'flex flex-col items-center justify-center gap-0.5 px-1 py-px',
        'rounded-full bg-white bg-gradient-to-t from-black/[3%] shadow-[0_1px_rgb(0_0_0_/_0.05)] ring-1 ring-black/10',
        '[:where(&_svg)]:fill-gray-900 [:where(&_svg)]:h-5',
      )}
    >
      {children}
    </motion.div>
  )
}

function KeyPad({id, letters, number, className}: {id: string, letters: string | React.ReactNode, number: string | React.ReactNode,className?: string}) {
  return (
    <Key name={id} className={className}>
        {number}
        <div className='flex text-[6px] -mt-1'>{letters}</div>
    </Key>
  )
}

function Hash() {
  return (
      <svg viewBox="0 0 7 14">
        <path d="M6.476 6.434h-1.22l-.36 1.74H6.1l-.145.747H4.746L4.327 11h-.773l.424-2.08H2.302L1.878 11h-.774l.42-2.08H.378l.15-.746h1.156l.354-1.74H.9l.145-.747H2.19l.409-2.019h.778l-.413 2.02h1.676l.413-2.02h.768l-.408 2.02h1.214l-.15.746Zm-3.674-.027-.37 1.789h1.707l.365-1.789H2.802Z" />
      </svg>
  )
}

function Asterisks() {
  return (
    <svg viewBox="0 0 6 14">
      <path d="M3.365 7.891h-.752l.113-1.75-1.455.977-.371-.65 1.574-.779L.9 4.916l.37-.65 1.461.983-.118-1.757h.752L3.247 5.25l1.461-.983.37.65-1.578.773 1.579.779-.371.65-1.46-.978.117 1.751Z" />
    </svg>
  )
}

function Plus() {
  return (
    <svg viewBox="0 0 8 14">
      <path d="M4.47 9.029v2.818h-.946V9.03H.94v-.952h2.584V5.404h.946v2.673h2.59v.952H4.47Z" />
    </svg>
  )
}

function Call() {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
    </svg>
  )
}

export function PhonePad({ highlighted = [] }: { highlighted?: string[] }) {
  return (
    <div className='relative pt-0.5'>
      <div className='absolute w-[180px] h-[170px] rounded-[18px] shadow-sm ring-1 ring-black/5 left-1/2 -translate-x-1/2 -top-5' />
      <div className='absolute w-[170px] h-[166px] rounded-[14px] shadow-sm ring-1 ring-black/5 left-1/2 -translate-x-1/2 -top-5'/>
      <div aria-hidden="true" className="flex flex-col gap-3">
        <Row>
          <KeyPad id="Seven" number="7" letters="PQRS" />
          <KeyPad id="Eight" number="8" letters="TUV" />
          <KeyPad id="Nine" number="9" letters="WXYZ" />
        </Row>
        <Row>
          <KeyPad id="Star" number={<Asterisks />} letters="" />
          <KeyPad id="Zero" number={<div className='[:where(&_svg)]:!h-3.5'>0 <div className='-mt-1.5'><Plus /></div></div>} letters="" />
          <KeyPad id="Hash" number={<Hash />} letters="" />
        </Row>
        <Row>
          <div className='flex w-full justify-center'>
            <KeyPad id="Call" number={<Call />} letters="" className='!bg-green-400 !ring-green-500 [:where(&_svg)]:!fill-white' />
          </div>
        </Row>
      </div>
    </div>
  )
}
