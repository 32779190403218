import React, { useState } from 'react';
import { Radio, RadioGroup } from '@headlessui/react';
import clsx from 'clsx';
import { Tooltip } from 'react-tooltip';

import Header from '../components/Header';
import Footer from '../components/Footer';
import PricingData from '../assets/data/pricing.json';
import { Logomark } from '../components/pricing-icon';
import { PricingPairs } from '../components/pricing-pairs';
import { Link } from 'react-router-dom';
import Dedicated from '../components/dedicated';
import { PartnerLogos } from './Home';

import 'react-tooltip/dist/react-tooltip.css';

const tooltips = {
  workload:
    "Workload units are essential for running Uzo apps. Each time your app runs, it uses a certain number of these units. Without available workload units, Uzo apps in production won't work.",
  storage:
    'This refers to the storage allocation that has been given to Uzp apps in production. Uzo apps provide the ability to store specific user data which can be accessed later for reporting, service rendering etc.',
  liveApp:
    'This refers to the number of apps that can be in a deployed at a time.',
};

const plans = [
  {
    name: 'Starter',
    featured: false,
    price: { Monthly: '¢ 60', Annually: '¢ 0' },
    description: '',
    button: {
      label: 'Get started for free',
      href: '/register',
    },
    features: ['1 live app', '1GB storage', '5,000 workload units'],
    logomarkClassName: 'fill-gray-300',
    tooltip: [tooltips.liveApp, tooltips.storage, tooltips.workload],
  },
  {
    name: 'Starter Plus',
    featured: true,
    price: { Monthly: '¢ 100', Annually: '¢ 0' },
    description: '',
    button: {
      label: 'Get started for free',
      href: '/register',
    },
    features: ['1 live app', '2GB storage', '10,000 workload units'],
    logomarkClassName: 'fill-primary',
    tooltip: [tooltips.liveApp, tooltips.storage, tooltips.workload],
  },
  {
    name: 'Standard',
    featured: false,
    price: { Monthly: '¢ 200', Annually: '¢ 0' },
    description: '',
    button: {
      label: 'Get started for free',
      href: '/register',
    },
    features: ['2 live app', '3GB storage', '20,000 workload units'],
    logomarkClassName: 'fill-gray-300',
    tooltip: [tooltips.liveApp, tooltips.storage, tooltips.workload],
  },
  {
    name: 'Standard Plus',
    featured: false,
    price: { Monthly: '¢ 280', Annually: '¢ 0' },
    description: '',
    button: {
      label: 'Get started for free',
      href: '/register',
    },
    features: ['2 live apps', '5GB storage', '30,000 workload units'],
    logomarkClassName: 'fill-gray-300',
    tooltip: [tooltips.liveApp, tooltips.storage, tooltips.workload],
  },
  {
    name: 'Professional',
    featured: false,
    price: { Monthly: '¢ 350', Annually: '¢ 700' },
    description:
      // 'You’ve been investing for a while. Invest more and grow your wealth faster.'
      '',
    button: {
      label: 'Subscribe',
      href: '/register',
    },
    features: [
      '3 live apps',
      '10GB storage',
      '50,000 workload units',
    ],
    logomarkClassName: 'fill-gray-500',
    tooltip: [tooltips.liveApp, tooltips.storage, tooltips.workload],
  },
  {
    name: 'Enterprise',
    featured: false,
    price: { Monthly: '¢ 600', Annually: '¢ 1,990' },
    description:
      // 'You’ve got a huge amount of assets but it’s not enough. To the moon.'
      '',
    button: {
      label: 'Subscribe',
      href: '/register',
    },
    features: [
      '5 live apps',
      '15GB storage',
      '100,000 workload units',
    ],
    logomarkClassName: 'fill-gray-500',
    tooltip: [tooltips.liveApp, tooltips.storage, tooltips.workload],
  },
];
export default function Pricing() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="flex flex-col min-h-screen justify-between">
        <div className="grow flex flex-col">
          <Header />

          <section
            id="pricing"
            aria-labelledby="pricing-title"
            className="border-t border-gray-200 bg-gray-100 py-20 sm:py-32"
          >
            <div className="container space-y-8">
              <Content />

              <PricingList />
            </div>

            <div className="mt-16 flex justify-center">
              <Link
                to="https://app.uzoussd.com/app/register"
                className="bg-primary px-12 py-2 rounded-full text-white font-bold text-[20px] bg-gradient-to-b from-yellow via-primary to-orange600"
              >
                Get Started
              </Link>
            </div>
          </section>

          <PartnerLogos />

          <section className="py-20 sm:py-32 hidden">
            <div className="p-8 max-w-6xl mx-auto bg-secondary rounded-2xl">
              <div className="md:flex block flex-col md:flex-row items-center md:space-x-4">
                <div className="md:basis-3/4">
                  <p className="font-bold text-2xl">
                    For dedicated codes and all other needs
                  </p>
                  <p className="mt-4">
                    Need more support and compliance features or
                    pricing doesn't work for your business? Don't
                    worry if the packages above don't meet your needs
                    or if your enterprise has unique requirements such
                    as acquiring a dedicated USSD code or privately
                    hosting your own version of UZO. Just reach out to
                    our sales team, and we will create a package
                    tailored to your specific needs.
                  </p>
                </div>
                <div className="md:basis-1/4 md:text-right text-left md:mt-0 mt-4">
                  <a
                    className="bg-primary px-6 py-2 rounded-full text-white font-bold text-[20px] bg-gradient-to-b from-yellow via-primary to-orange600"
                    href={`mailto:clientservices@mobivs.com`}
                  >
                    Contact Sales
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>

      <Tooltip id="pricing-tooltip" style={{ maxWidth: '300px' }} />
    </>
  );
}

const Content = () => {
  return (
    // <div className="bg-secondary">
    //   <div className="relative overflow-clip overflow-hidden">
    //     <div className="container mx-auto py-12">
    //       <div className="flex items-center justify-center space-x-8">
    //         <div className="text-center max-w-screen-md">
    //           <div className="font-bold text-[40px] leading-tight">
    //             The ideal package tailored to your needs
    //           </div>
    //           <div className="leading-tight mt-4">
    //             Choose a package that meets your needs. Whether you're just getting started or looking for complex integration, <b>Uzo</b> has what you need.
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="mx-auto max-w-2xl text-center">
      <h2
        id="pricing-title"
        className="text-5xl font-medium tracking-tight bg-gradient-to-b from-yellow to-orange bg-clip-text text-transparent pb-3"
      >
        Simple pricing, for everyone.
      </h2>
      <p className="mt-2 text-lg text-gray-600">
        Choose a package that meets your needs. Whether you're just
        getting started or looking for complex integration, <b>Uzo</b>{' '}
        has what you need.
      </p>
    </div>
  );
};

function CheckIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path
        d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
        fill="currentColor"
      />
      <circle
        cx="12"
        cy="12"
        r="8.25"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function InfoIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
        <path
          fill="currentColor"
          d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m-.01 8H11a1 1 0 0 0-.117 1.993L11 12v4.99c0 .52.394.95.9 1.004l.11.006h.49a1 1 0 0 0 .596-1.803L13 16.134V11.01c0-.52-.394-.95-.9-1.004zM12 7a1 1 0 1 0 0 2a1 1 0 0 0 0-2"
        />
      </g>
    </svg>
  );
}

function Plan({
  name,
  price,
  description,
  features,
  activePeriod,
  logomarkClassName,
  featured = false,
  tooltip,
}: {
  name: string;
  price: {
    Monthly: string;
    Annually: string;
  };
  description: string;
  features: Array<string>;
  activePeriod: 'Monthly' | 'Annually';
  logomarkClassName?: string;
  featured?: boolean;
  tooltip?: string[];
}) {
  return (
    <section
      className={clsx(
        'flex flex-col overflow-hidden rounded-3xl p-6 shadow-lg shadow-gray-900/5',
        featured
          ? 'order-first bg-gray-900 lg:order-none'
          : 'bg-white'
      )}
    >
      <h3
        className={clsx(
          'flex items-center text-sm font-semibold',
          featured ? 'text-white' : 'text-gray-900'
        )}
      >
        <Logomark
          className={clsx('h-6 w-6 flex-none', logomarkClassName)}
        />
        <span className="ml-4">{name}</span>
      </h3>
      <p
        className={clsx(
          'relative mt-5 flex text-4xl tracking-tight items-center',
          featured ? 'text-white' : 'text-gray-900'
        )}
      >
        <span
          className={clsx(
            'transition duration-300',
            activePeriod === 'Annually' &&
              'pointer-events-none translate-x-6 select-none opacity-0'
          )}
        >
          {price.Monthly}
        </span>
        <div
          className={clsx(
            'text-sm/5 ms-2 mt-2.5',
            featured ? 'text-white' : 'text-gray-950/75'
          )}
        >
          <p>per month</p>
        </div>
      </p>
      <p
        className={clsx(
          'mt-3 text-sm',
          featured ? 'text-gray-300' : 'text-gray-700'
        )}
      >
        {description}
      </p>
      <div className="order-last mt-6">
        <ul
          className={clsx(
            '-my-2 divide-y text-sm',
            featured
              ? 'divide-gray-800 text-gray-300'
              : 'divide-gray-200 text-gray-700'
          )}
        >
          {features.map((feature, idx) => (
            <li key={feature} className="flex py-2 items-center">
              <CheckIcon
                className={clsx(
                  'h-6 w-6 flex-none',
                  featured ? 'text-white' : 'text-cyan-500'
                )}
              />
              <span className="ml-4">{feature}</span>
              {tooltip && (
                <div
                  data-tooltip-id="pricing-tooltip"
                  data-tooltip-content={tooltip[idx]}
                  className="ms-2"
                >
                  <InfoIcon className="h-4 w-4 flex-none" />
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

const PricingList = () => {
  enum Services {
    AppBuilder = 'App Builder',
    UssdCode = 'USSD Shared Code',
    Dedicated = 'Dedicated Code',
  }

  const [selectedService, setSelectedService] = useState<Services>(
    Services.AppBuilder
  );

  const showAppBuilderPackages = () => {
    setSelectedService(Services.AppBuilder);
  };

  const showUssdCodesPackages = () => {
    setSelectedService(Services.UssdCode);
  };

  return (
    <div className="">
      <div className="mx-auto">
        <div className="max-w-6xl mx-auto">
          <div className="flex justify-center">
            <div className="relative">
              <RadioGroup
                value={selectedService}
                onChange={setSelectedService}
                className="grid grid-cols-3"
              >
                {Object.values(Services).map((period) => (
                  <Radio
                    key={period}
                    value={period}
                    className={clsx(
                      'text-center',
                      'cursor-pointer border border-gray-300 px-[calc(theme(spacing.3)-1px)] py-[calc(theme(spacing.4)-1px)] text-lg text-gray-700 outline-2 outline-offset-2 transition-colors hover:border-gray-400',
                      {
                        'rounded-l-lg':
                          period === Services.AppBuilder,
                        '-ml-px rounded-r-lg':
                          period === Services.Dedicated,
                      }
                    )}
                  >
                    {period}
                  </Radio>
                ))}
              </RadioGroup>
              <div
                aria-hidden="true"
                className={clsx(
                  'pointer-events-none absolute inset-0 z-10 grid grid-cols-3 overflow-hidden rounded-lg bg-primary transition-all duration-300',
                  // '[clip-path:inset(0_0_0_calc(50%-1px))]',
                  {
                    '[clip-path:inset(0_66.66%_0_0)]':
                      selectedService === Services.AppBuilder,
                  },
                  {
                    '[clip-path:inset(0_33.33%_0_33.33%)]':
                      selectedService === Services.UssdCode,
                  },
                  {
                    '[clip-path:inset(0_0_0_66.66%)]':
                      selectedService === Services.Dedicated,
                  }
                )}
              >
                {Object.values(Services).map((period) => (
                  <div
                    key={period}
                    className={clsx(
                      'py-4 text-center text-lg font-semibold text-white',
                      period === Services.UssdCode && '-ml-px'
                    )}
                  >
                    {period}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {selectedService === Services.AppBuilder && (
            <>
              <UzoPrices />
            </>
          )}

          {selectedService === Services.UssdCode && (
            <>
              <PricingPairs />
            </>
          )}

          {selectedService === Services.Dedicated && (
            <>
              <Dedicated />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

function UzoPrices() {
  return (
    <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-10 sm:mt-20 lg:max-w-none lg:grid-cols-3">
      {plans.map((plan) => (
        <Plan key={plan.name} {...plan} activePeriod={'Monthly'} />
      ))}
    </div>
  );
}
