import React from 'react';
import clsx from 'clsx';

import SlackIcon from '../assets/images/resources/slack.svg';

function Row({ children }: { children: React.ReactNode }) {
  return (
    <div className="group relative">
      <div className="absolute inset-x-0 top-1/2 h-0.5 bg-gradient-to-r from-[#1f2937]/15 from-[2px] to-[2px] bg-[length:12px_100%]" />
      <div className="absolute inset-x-0 bottom-0 h-0.5 bg-gradient-to-r from-white/5 from-[2px] to-[2px] bg-[length:12px_100%] group-last:hidden" />
      {children}
    </div>
  );
}

function Logo({
  label,
  src,
  className,
}: {
  label: string;
  src: string;
  className: string;
}) {
  return (
    <div
      className={clsx(
        className,
        'absolute top-2  items-center gap-2 whitespace-nowrap px-3 py-1',
        'rounded-full bg-gradient-to-t from-gray-50 from-50% to-white ring-1 ring-inset ring-gray-900/10',
        '[--move-x-from:-100%] [--move-x-to:calc(100%+100cqw)] ',
        '[animation-iteration-count:infinite] [animation-name:move-x] [animation-timing-function:linear] [animation-play-state:running]'
      )}
    >
      {/* <img alt="" src={src} className="size-4" /> */}
      <span className="text-sm/6 font-medium text-[#6366F1]">
        {label}
      </span>
    </div>
  );
}

function Integration() {
  return (
    <div
      aria-hidden="true"
      className="relative h-full overflow-hidden"
    >
      <div className="absolute h-48 sm:h-60 lg:h-40 inset-0 grid grid-cols-1 pt-2 [container-type:inline-size]">
        <Row>
          <Logo
            label="Apple Store"
            src={SlackIcon}
            className="[animation-delay:0s] [animation-duration:0s] translate-x-[5px]"
          />
          <Logo
            label="Slack"
            src={SlackIcon}
            className="[animation-delay:0s] [animation-duration:0s] translate-x-[135px]"
          />
          <Logo
            label="Hubtel"
            src={SlackIcon}
            className="[animation-delay:-40s] [animation-duration:0s] translate-x-[220px]"
          />
        </Row>
        <Row>
          <Logo
            label="Route SMS"
            src={SlackIcon}
            className="[animation-delay:0s] [animation-duration:0s] translate-x-[60px]"
          />
          <Logo
            label="Paystack"
            src={SlackIcon}
            className="[animation-delay:-10s] [animation-duration:0s] translate-x-[180px]"
          />
        </Row>
        <Row>
          <Logo
            label="USSD Cloud"
            src={SlackIcon}
            className="[animation-delay:0s] [animation-duration:0s] translate-x-[30px]"
          />
          <Logo
            label="Google Drive"
            src={SlackIcon}
            className="[animation-delay:-13s] [animation-duration:0s] translate-x-[160px]"
          />
        </Row>
      </div>
    </div>
  );
}

export default Integration;
