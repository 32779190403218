import { clsx } from 'clsx';
import { motion } from 'framer-motion';
import SlackIcon from '../assets/images/resources/slack.svg';

function Circle({
  size,
  delay,
  opacity,
}: {
  size: number;
  delay: number;
  opacity: string;
}) {
  return (
    <motion.div
      animate={{
        width: [`${size}px`, `${size + 10}px`, `${size}px`],
        height: [`${size}px`, `${size + 10}px`, `${size}px`],
        transition: {
          duration: 0.75,
          repeat: Infinity,
          repeatDelay: 1.25,
          ease: 'easeInOut',
          delay,
        },
      }}
      style={{ '--opacity': opacity } as React.CSSProperties}
      className={clsx(
        'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full',
        'bg-[radial-gradient(circle,transparent_25%,color-mix(in_srgb,_theme(colors.blue.500)_var(--opacity),transparent)_100%)]',
        'ring-1 ring-inset ring-blue-500/[8%]'
      )}
    />
  );
}

function Circles() {
  return (
    <div className="absolute inset-0">
      <Circle size={330} opacity="3%" delay={0.45} />
      <Circle size={260} opacity="5%" delay={0.3} />
      <Circle size={180} opacity="5%" delay={0.15} />
      <Circle size={110} opacity="10%" delay={0} />
      <div className="absolute inset-0 bg-gradient-to-t from-white to-35%" />
    </div>
  );
}

function MainLogo() {
  return (
    <div className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 top-1/2 flex size-12 items-center justify-center rounded-full bg-white text-primary font-bold shadow ring-1 ring-black/5">
      {/* <Mark className="h-9 fill-black" /> */} Uzo
    </div>
  );
}

function Logo({
  src,
  left,
  top,
  hover,
}: {
  src: string;
  left: number;
  top: number;
  hover: { x: number; y: number; rotate: number; delay: number };
}) {
  return (
    <motion.div
      animate={{
        x: [0, hover.x, 0],
        y: [0, hover.y, 0],
        rotate: [0, hover.rotate, 0],
        transition: {
          duration: 0.75,
          repeat: Infinity,
          repeatDelay: 1.25,
          ease: 'easeInOut',
          delay: hover.delay,
        },
      }}
      style={{ left, top } as React.CSSProperties}
      className="absolute size-10 rounded-full bg-white shadow ring-1 ring-black/5 flex justify-center items-center"
    >
      {/* <img
      alt=""
      src={src}
      style={{ left, top } as React.CSSProperties}
      className="size-6"
      /> */}
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className="text-[#6366F1]"
      >
        <path
          fill="currentColor"
          d="m7.9 14.1l.09-.09c.27-.27.32-.71.08-1.01C7.36 12.09 7 11.01 7 10c0-1.08.35-2.16 1.04-3.01c.25-.3.21-.75-.07-1.02L7.9 5.9c-.34-.34-.9-.3-1.2.08c-.91 1.18-1.4 2.6-1.4 4.02s.49 2.84 1.4 4.02c.3.38.86.42 1.2.08M18.51 3.49l-.08.08c-.3.3-.29.76-.03 1.08c1.26 1.53 1.9 3.48 1.9 5.35s-.63 3.81-1.9 5.35c-.28.33-.23.83.08 1.14c.35.35.93.31 1.24-.07C21.29 14.54 22 12.31 22 10c0-2.32-.79-4.55-2.31-6.43c-.3-.37-.85-.41-1.18-.08"
        />
        <path
          fill="currentColor"
          d="m5.57 3.57l-.08-.08c-.33-.33-.88-.29-1.18.08C2.79 5.45 2 7.68 2 10s.79 4.55 2.31 6.43c.3.37.85.42 1.18.08l.08-.08c.3-.3.29-.76.03-1.08c-1.27-1.54-1.9-3.48-1.9-5.35s.63-3.81 1.9-5.35c.26-.32.27-.78-.03-1.08m10.5 10.5c.36.36.95.32 1.26-.09c.9-1.18 1.37-2.58 1.37-3.98c-.08-1.41-.51-2.83-1.4-4.01a.797.797 0 0 0-1.2-.09l-.08.08c-.27.27-.32.71-.08 1.01c.7.92 1.06 2 1.06 3.01c0 1.07-.34 2.13-1.01 2.98c-.26.32-.22.79.08 1.09M14.5 10c0-1.6-1.51-2.85-3.18-2.41c-.8.21-1.46.85-1.7 1.65c-.32 1.06.06 2.04.76 2.64l-2.96 8.87c-.21.62.25 1.25.9 1.25c.41 0 .77-.26.9-.65L9.67 20h4.67l.45 1.35a.949.949 0 1 0 1.8-.6l-2.96-8.87c.53-.46.87-1.12.87-1.88m-4.17 8L12 13l1.67 5z"
        />
      </svg>
    </motion.div>
  );
}

export function Connectors() {
  return (
    <div
      aria-hidden="true"
      className="relative h-full overflow-hidden"
    >
      <Circles />
      <div className="absolute left-1/2 h-full w-full -translate-x-1/2">
        <MainLogo />

        <Logo
          src={SlackIcon}
          left={255}
          top={10}
          hover={{ x: 4, y: -5, rotate: 6, delay: 0.3 }}
        />
        <Logo
          src={SlackIcon}
          left={255}
          top={80}
          hover={{ x: 3, y: 5, rotate: 7, delay: 0.2 }}
        />
        <Logo
          src={SlackIcon}
          left={144}
          top={-6}
          hover={{ x: -2, y: -5, rotate: -6, delay: 0.15 }}
        />
        <Logo
          src={SlackIcon}
          left={36}
          top={56}
          hover={{ x: -4, y: -5, rotate: -6, delay: 0.35 }}
        />
        <Logo
          src={SlackIcon}
          left={116}
          top={133}
          hover={{ x: -3, y: 5, rotate: 3, delay: 0.15 }}
        />
      </div>
    </div>
  );
}
